<template>
  <div class="space" v-if="model">
    <div class="space-background">
      <img :src="model.fileName" />
    </div>
    <div class="space-logo wow fadeInDown" data-wow-delay="0.2s">
      <img src="/images/bg/space-logo.png" />
    </div>
    <div class="content-bar">
      <div class="content-cont">
        <div class="title wow fadeInUp" data-wow-delay="1.2s">
          <span>{{ model.title }}</span>
        </div>
        <div class="detail-text wow fadeInUp" data-wow-delay="1.6s">
          <div class="content">
            <span v-html="model.description"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store/index";
import WOW from "wow.js";
new WOW().init();
export default {
  name: "legend-artist",
  props: ["data"],
  created() {
    this.startTimer();
  },
  data() {
    return {
      destroyed: false
    };
  },
  methods: {
    startTimer() {
      setTimeout(() => {
        if (!this.destroyed) store.dispatch("setNextPlaylist");
      }, this.model.displaySecond * 1000);
    }
  },
  computed: {
    model() {
      return this.$store.state.backgroundImage;
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss">
@import "../assets/animate/animate.css";

@font-face {
  font-family: "Montserrat-Bold";
  src: url(/fonts/Montserrat-Bold.otf);
}

.space {
  .space-background {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .space-logo {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 36px;
    text-align: center;

    img {
      width: 308px;
      height: auto;
    }
  }

  .content-bar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 120px;

    .content-cont {
      width: 100%;
      max-width: 1360px;
      text-align: left;
      margin: 0 auto;

      .title {
        position: relative;
        z-index: 55;
        background: rgba(240, 110, 66, 0.6);
        border-radius: 54px 54px 54px 0;
        font-family: "Montserrat-Bold";
        padding: 20px 48px;
        display: inline-block;
        margin-bottom: -17px;
        color: white;
        max-width: 80%;

        &:after {
          content: "";
          background: url("/images/bg/space-title-shadow.png") center top no-repeat;
          background-size: 100% 100%;
          width: 90%;
          height: 20px;
          position: absolute;
          left: 0;
          bottom: -20px;
        }

        span {
          font-size: 56px;
          line-height: 68px;
          max-height: 136px;
          -webkit-line-clamp: 2;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }

      .detail-text {
        background: url("/images/bg/space-content-shadow.png") right top no-repeat rgba(255, 255, 255, 0.5);
        background-size: 100% auto;
        position: relative;
        z-index: 52;
        padding: 32px 48px;
        color: white;
        font-size: 42px;
        line-height: 1.2;
        border-radius: 0 0 54px 54px;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);

        .content {
          display: block;

          span {
            max-height: 172px;
            -webkit-line-clamp: 4;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}</style>
